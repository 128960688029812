<template>
  <div class="footer">
    <div class="title">
      <ul style="cursor: pointer">
        <li @click="$router.push('/messageBack')">{{$t('footer.ul_li1')}}</li>
        <li @click="gotoHelp()">{{$t('footer.ul_li2_phone')}}</li>
        <li>
          <a style="color: #B9BABD" target="_blank" :href="$registered_address.okx_address()">{{$t('footer.ul_li3')}}</a>
          <span style="display: inline-block;cursor: pointer;color: #E3B936;border-bottom: #E3B936 1px solid;line-height: 14px;margin-left: 5px" @click="copy()">{{ $t('alter.copy') }}</span>
        </li>
        <li @click="$router.push('/robot/UserUsageProtocol')">{{$t('footer.ul_li4_phone')}}</li>
      </ul>
    </div>

    <div v-if="showChangeLanguage" class="cut-mode">
      <el-dropdown trigger="click" placement="bottom" @command="selectLang">
        <el-button type="primary">
          <div>
            <div>
              <img style="width: 20px;height: 20px" src="../../assets/img/footer/language.png" alt="">
              <span>{{selectLanguage}}</span>
            </div>
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </div>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  command="简体中文">简体中文</el-dropdown-item>
          <el-dropdown-item  command="繁体中文">繁体中文</el-dropdown-item>
          <el-dropdown-item  command="English">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-else style="padding: 52px 0 52px 0"></div>
    <div class="icons">
      <a href="https://t.me/UPLUS_ELLA" target="_blank"><img @mouseover="icons.tel=true" @mouseleave="icons.tel=false" :src="require('../../assets/img/footer/telgrame'+(icons.tel ? '_pre' :'')+'.png')" alt=""></a>
      <a href="https://twitter.com/uplus666" target="_blank"><img style="margin-left: 0" @mouseover="icons.tiwer=true" @mouseleave="icons.tiwer=false" :src="require('../../assets/img/footer/tiwer'+(icons.tiwer ? '_pre' :'')+'.png')" alt=""></a>
      <img @mouseover="icons.facebook=true" @mouseleave="icons.facebook=false" :src="require('../../assets/img/footer/facebook'+(icons.facebook ? '_pre' :'')+'.png')" alt="">
      <img @mouseover="icons.ins=true" @mouseleave="icons.ins=false" :src="require('../../assets/img/footer/ins'+(icons.ins ? '_pre' :'')+'.png')" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer_sm",
  data(){
    return{
      icons:{
        tel:false,
        facebook:false,
        tiwer:false,
        ins:false
      },
      selectLanguage:'简体中文',
      showChangeLanguage: true
    }
  },
  created() {
    // let thisTime = new Date().getTime();
    // let loginTime = localStorage.getItem('loginTime')
    // if ((thisTime - loginTime) >= 1000*60*60*4){ //1000*60*60*4
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('userInfo')
    //   localStorage.removeItem('loginTime')
    // }

    // if (localStorage.getItem('token')){
    //   this.showChangeLanguage = true
    // }

    // if (this.$route.path=='/') this.selectLanguage='English'
    // if (!localStorage.getItem('token') && (this.$route.path=='/' || this.$route.path=='/login' || this.$route.path=='/register' || this.$route.path=='/forget_password')) {
    //   this.selectLanguage='English'
    // } else {
      if (localStorage.getItem('language')){
        this.selectLanguage = localStorage.getItem('language')
      } else {
        this.selectLanguage = '简体中文'
        localStorage.setItem('language', this.selectLanguage)
      }
      this.selectLanguage==='繁体中文' ? this.$i18n.locale='zh-tw' : this.selectLanguage==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
    // }
  },
  methods:{
    selectLang(e){
      this.selectLanguage=e
      localStorage.setItem('language', e)
      // e==='繁体中文' ? this.$i18n.locale='zh'  : this.$i18n.locale='en'
      e==='繁体中文' ? this.$i18n.locale='zh-tw' : e==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
      location.reload()
    },
    gotoHelp(){
      if (this.$route.path.indexOf('/help') < 0){
        localStorage.setItem('lastPath', this.$route.path)
      }
      this.$router.push('/help')
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },
  }
}
</script>

<style scoped>
.footer{
  height: 216px;
  background: #151924;
  width: 100%;
  padding-top: 33px;
}
.icons img {
  width: 32px;
  height: 32px;
  margin-right: 28px;
}
.icons img:first-child{
  margin-left: 16px;
}
.title ul{
  list-style-type: none;
  color: #A9A9AD;
  font-size: 14px;
  padding: 0;
  display: flex;
  justify-content: space-around;
}
.el-button--primary{
  background: none;
  border: 1px solid #292B2E;
  width: 80%;
  height: 40px;
  border-radius: 2px;
  padding: 0 !important;
}
.el-dropdown{
  width: 100%;
  margin: 32px 0 32px 0;
  text-align: center;
}
.cut-mode > div > button > span > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #B9BABD;
  font-size: 14px;
  padding: 0 16px;
}
.cut-mode > div > button > span > div div{
  display: flex;
  align-items: center;
}
.cut-mode > div > button > span > div div img{
  margin-right: 8px;
}



</style>
