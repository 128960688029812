<template>
  <div class="footer">
      <div  class="footer_left">
        <div style="flex-wrap: nowrap;display: inline-flex;align-items: center">
          <img style="width: 72px" src="../../assets/img/head_logo.png" alt="">
          <div class="title" style="">{{$t('footer.title')}}</div>
        </div>
         <div class="effect">
          <div class="icons">
<!--            <a href="https://t.me/UPLUS_ELLA" target="_blank"><img @mouseover="icons.tel=true" @mouseleave="icons.tel=false" :src="require('../../assets/img/footer/telgrame'+(icons.tel ? '_pre' :'')+'.png')" alt=""></a>-->
<!--            <a href="https://twitter.com/uplus666" target="_blank"><img @mouseover="icons.tiwer=true" @mouseleave="icons.tiwer=false" :src="require('../../assets/img/footer/tiwer'+(icons.tiwer ? '_pre' :'')+'.png')" alt=""></a>-->
<!--            <img @mouseover="icons.facebook=true" @mouseleave="icons.facebook=false" :src="require('../../assets/img/footer/facebook'+(icons.facebook ? '_pre' :'')+'.png')" alt="">-->
<!--            <img @mouseover="icons.ins=true" @mouseleave="icons.ins=false" :src="require('../../assets/img/footer/ins'+(icons.ins ? '_pre' :'')+'.png')" alt="">-->
            <a href="https://t.me/UPLUS_ELLA" target="_blank"><img @mouseover="icons.tel=true" @mouseleave="icons.tel=false" :src="require('../../assets/img/footer/telgrame_new.png')" alt=""></a>
            <a href="https://twitter.com/uplus666" target="_blank"><img @mouseover="icons.tiwer=true" @mouseleave="icons.tiwer=false" :src="require('../../assets/img/footer/tiwer_new.png')" alt=""></a>
            <img @mouseover="icons.facebook=true" @mouseleave="icons.facebook=false" :src="require('../../assets/img/footer/facebook'+(icons.facebook ? '_pre' :'')+'.png')" alt="">
            <img @mouseover="icons.ins=true" @mouseleave="icons.ins=false" :src="require('../../assets/img/footer/ins'+(icons.ins ? '_pre' :'')+'.png')" alt="">
          </div>
<!--          <div v-if="showChangeLanguage" class="cut-mode">-->
<!--            <el-dropdown trigger="click" placement="bottom" @command="selectLang">-->
<!--              <el-button type="primary">-->
<!--                <div>{{selectLanguage}}<i class="el-icon-caret-bottom el-icon&#45;&#45;right"></i></div>-->
<!--              </el-button>-->
<!--              <el-dropdown-menu style="width: 98px" slot="dropdown">-->
<!--                <el-dropdown-item command="简体中文">简体中文</el-dropdown-item>-->
<!--                <el-dropdown-item command="繁体中文">繁體中文</el-dropdown-item>-->
<!--                <el-dropdown-item command="English">English</el-dropdown-item>-->
<!--              </el-dropdown-menu>-->
<!--            </el-dropdown>-->
<!--          </div>-->
        </div>
      </div>
      <div class="footer_right">
                <ul style="cursor: pointer">
                  <li @click="feedback()">{{$t('footer.ul_li1')}}</li>
                  <li @click="gotoHelp()">{{$t('footer.ul_li2')}}</li>
                  <li>
                    <a style="display: inline-block;color: #B9BABD" target="_blank" :href="$registered_address.okx_address()">{{$t('footer.ul_li3')}}<br/><span style="display: block;margin-top: -8px">({{ $registered_address.okx_address() }})</span></a>
                    <span style="display: inline-block;cursor: pointer;margin-left: 5px; color: #E3B936;border-bottom: #E3B936 1px solid;line-height: 14px" @click="copy()">{{ $t('alter.copy') }}</span>
                  </li>
                  <li @click="$router.push('/robot/UserUsageProtocol')">{{$t('footer.ul_li4')}}</li>
                  <li @click="download_app">{{$t('footer.download_app')}}</li>
                </ul>
      </div>


    <el-dialog
        class="dialog-mb"
        title="意見反饋"
        :visible.sync="showFeedBack"
        width="500px"
        style="overflow: unset;">
      <span style="font-size: 12px; color: #72777E">選擇問題類型</span>
      <el-select v-model="feedbackName" style="width: 100%; margin-top: 10px;" filterable placeholder="請選擇問題類型">
        <el-option v-for="(item) in feedbackList" :key="item.id" :label="item.name" :value="item.name"></el-option>
      </el-select>
      <span style="display: block; margin: 10px 0px 5px 0px;font-size: 12px; color: #72777E">問題描述</span>
      <div style="position: relative">
        <el-input style="font-size: 14px" type="textarea" :rows="6" placeholder="請輸入内容" v-model="feedbackContent" @input="totalinput($event)"></el-input>
        <span style="position:absolute; color: #C0C4C8; font-size: 12px; right: 8px;bottom: 6px">{{ thisNum }}/{{ totalNum }}</span>
      </div>
      <el-upload
          class="avatar-uploader"
          action="https://api.felton.fit/api/other/uploadImage"
          name="pic"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-preview="handlePictureCardPreview">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <div v-else class="" style="text-align: center; width: 100px; height: 100px;">
          <img style="width: 28px; height: 23px; margin-top: 25px; text-align: center" src="../../assets/img/footer/add_pic.png">
          <span style="display: block;font-size: 12px;margin-top:5px;color: #C0C4C8">添加照片</span>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer" style="">
    <el-button type="primary" style="width: 100%; background-color: #AB54DB" @click="submit()">提交</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :visible.sync="showcode"
        title=""
        :center="true"
        :close-on-click-modal="true"
        width="315px"
        :show-close="true"
        class="dialog_down">
      <div v-if="!this.$util._isMobile()" style="text-align: center; border-radius: 20px">
        <img style="width: 200px; height: 200px; margin: 0 auto; display: block" src="../../assets/img/download_code.png" alt="pointer">
        <span style="font-size: 20px;margin-top: 30px;display: block">扫描二维码下载</span>
      </div>
      <div v-if="this.$util._isMobile()" style="width: 226px; margin: 0 auto;text-align: center; vertical-align: middle">
        <div style="background: #AB54DB; border-radius: 10px;padding: 10px 30px;" @click="download_android()">
          <img style="display: inline-block;vertical-align: middle; width: 36px" src="../../assets/img/android.png">
          <span style="font-size: 20px; color: #FFFFFF; margin-left: 15px;display: inline-block;vertical-align: middle">下载Android</span>
        </div>
        <div style="background: #7F848C; border-radius: 10px; margin-top: 100px;padding: 10px 30px">
          <img style="display: inline-block;vertical-align: middle; width: 36px" src="../../assets/img/iOS.png">
          <span style="font-size: 20px; color: #FFFFFF; margin-left: 15px;display: inline-block;vertical-align: middle">IOS即将上线</span>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import {feedbackRequest} from "../../server/auth";
import {baseUrl} from '../../utils/request'

export default {
  name: "Footer",
  data(){
    return{
      icons:{
        tel:false,
        facebook:false,
        tiwer:false,
        ins:false
      },
      feedbackName: '',
      feedbackContent: '',
      showFeedBack: false,
      imageUrl: '',
      thisNum: 0,
      totalNum: 500,
      feedbackList: [
        {
          id: 1,
          name: '投訴'
        },
        {
          id: 2,
          name: '建議'
        },
        {
          id: 3,
          name: '故障'
        },
        {
          id: 4,
          name: '其他'
        },
      ],
      selectLanguage:'简体中文',
      showChangeLanguage: true,
      showcode: false
    }
  },
  created() {
    // let thisTime = new Date().getTime();
    // let loginTime = localStorage.getItem('loginTime')
    // if ((thisTime - loginTime) >= 1000*60*60*4){ //1000*60*60*4
    //   localStorage.removeItem('token');
    //   localStorage.removeItem('userInfo')
    //   localStorage.removeItem('loginTime')
    // }

    // if (localStorage.getItem('token')){
    //   this.showChangeLanguage = true
    // }
    // if (!localStorage.getItem('token') && (this.$route.path=='/' || this.$route.path=='/login' || this.$route.path=='/register' || this.$route.path=='/forget_password')) {
    //   this.selectLanguage='English'
    // } else {
      if (localStorage.getItem('language')){
        this.selectLanguage = localStorage.getItem('language')
      } else {
        this.selectLanguage = '简体中文'
        localStorage.setItem('language', this.selectLanguage)
      }
      this.selectLanguage==='繁体中文' ? this.$i18n.locale='zh-tw' : this.selectLanguage==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
    // }
  },
  methods:{
    selectLang(e){
      this.selectLanguage=e
      this.selectLanguage=e
      localStorage.setItem('language', e)
      e==='繁体中文' ? this.$i18n.locale='zh-tw' : e==='简体中文' ? this.$i18n.locale='zh' : this.$i18n.locale='en'
      location.reload()
    },
    feedback(){
      // this.showFeedBack = true;
      // this.$emit('showFeedBack',true)
      console.log(this.$route.path)
      if (this.$route.path.indexOf('/user_center') > -1 || this.$route.path.indexOf('/robot') > -1 || this.$route.path.indexOf('/rebate') > -1 || this.$route.path.indexOf('/user') > -1){
        this.$store.state.showFeedBack = true
      } else {
        this.$store.state.showFeedBack = true
        if(!localStorage.getItem('token')){
          this.$router.push('./')
        } else {
          this.$router.push('./center')
        }
        // this.$router.push('./center')
      }
    },
    totalinput(value){
      if (value.length>500){
        this.feedbackContent = value.substr(0, 500)
        this.$message.warning('最多只能輸入500個字符')
      }
      this.thisNum = this.feedbackContent.length
    },
    async submit(){
      if (!this.feedbackName){
        this.$message.warning('请选择问题类型')
        return
      }
      if (!this.feedbackContent){
        this.$message.warning('请输入问题描述')
        return
      }
      const res = await feedbackRequest(this.feedbackName, this.feedbackContent, this.imageUrl)
      if (!res) return
      if (res.success){
        this.$message.success('收到，非常感謝您提的寶貴意見')
        this.showFeedBack = false
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt12M = file.size / 1024 / 1024 < 12;

      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG或者PNG 格式!');
      }
      if (!isLt12M) {
        this.$message.error('图片大小不能超过 12MB!');
      }
      return (isPNG || isJPG) && isLt12M;
    },
    handlePictureCardPreview(file,fileList) {
      console.log(12)
    },
    gotoHelp(){
      if (this.$route.path.indexOf('/help') < 0){
        localStorage.setItem('lastPath', this.$route.path)
      }
      this.$router.push('/help')
    },
    copy() {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.$registered_address.okx_address());
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('rebate.copy_success'))
      }
      document.body.removeChild(input);
    },
    download_app(){
      if (this.$util._isMobile()){
        this.$router.push({name: 'download', query:{showBack: 1}})
      } else {
        this.$router.push({name: 'download', query:{showBack: 1}})
        // this.showcode = true
      }
    },

    download_android(){
      let a = document.createElement('a')
      a.href ="https://felton-oss.oss-cn-beijing.aliyuncs.com/file/download/felton-app.apk"
      a.click();
    },

  }
}
</script>

<style scoped>
.footer{
  height: 279px;
  color:#B9BABD;
  display: flex;
  justify-content: space-between;
  padding: 0 130px;
  background-color: #191919;
}
.footer_left,.footer_right{
  margin-top: 58px;
}
.footer .title{
  font-size: 16px;
  font-weight: 400;
  color: #B9BABD;
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}
.icons img {
  width: 32px;
  height: 32px;
  margin-right: 32px;
}
.footer_left .effect{
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cut-mode{
  margin-left: 160px;
}
.el-button--primary{
  background-color: rgba(255, 255, 255, 0.1);
  width: 180px;
  height: 40px;
  border-radius: 2px;
  border: 0;
  padding: 0 !important;
}
.cut-mode > div > button > span > div{
  display: flex;
  justify-content: space-around;
  color: #B9BABD;
}
.footer_right ul li{
  line-height: 30px;
  font-size:14px ;
  color: #B9BABD;
}
>>>.el-textarea__inner{
  font-size: 14px;
}
.avatar-uploader{
  margin-top: 20px;
  width: 100px;
  height: 100px;
  border: 1px #EAECEF solid;
  border-radius: 1px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.dialog-mb >>>.el-dialog{
  height: 550px;
}

.dialog_down >>>.el-dialog--center{
  border-radius: 12px;
  padding: 40px 0;
}
</style>
